@import 'src/legacy/Styles/Colors.scss';

$main-menu-width: 300px;

.main-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  right: -$main-menu-width;
  width: $main-menu-width;
  height: 100%;
  background-color: #ffffff;
  transition: right 0.5s;
  z-index: 4;

  &.show {
    right: 0px;
    transition: right 0.5s;
  }

  .menu-user-profile {
    height: 64px;
    .inner-profile {
      display: flex;
      .user-pic {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.1);
        margin-left: 20px;
        margin-top: 7px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .user-name {
        text-transform: uppercase;
        margin-left: 10px;
        line-height: 64px;
        font-size: 14pt;
        display: inline-block;
        max-width: calc(100% - 90px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: $black;
      }
    }
  }

  .items {
    color: $blue;
    ul {
      padding: 0px;
      li {
        list-style: none;
        font-size: 16pt;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.separator {
          margin-top: 20px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            left: 10px;
            top: -10px;
            width: calc(100% - 20px);
          }
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        .notification-number {
          min-width: 30px;
          height: 30px;
          line-height: 30px;
          background-color: #ee2b38;
          color: #fff;
          display: block;
          width: fit-content;
          text-align: center;
          border-radius: 10px;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          font-size: 12pt;
          box-sizing: border-box;
          padding: 0px 10px;
        }
      }
    }
  }

  .unlogged {
    position: relative;
    margin: 40px 20px 20px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .button:first-child {
      margin-bottom: 10px;
    }

    .help-center-button {
      margin-top: auto;
      // padding-top: 2rem;
      .inner{
          height: 100%;
          
        a{
          color: black;
          text-decoration: none;
          padding: 1rem;
          display: block;

        }
      }

    }
  }

  .publish-event {
    width: 100%;
    margin-bottom: var(--chakra-space-2);
  }

  .organizers-page {
    width: 100%;
    margin-bottom: var(--chakra-space-2);

    .inner {
      color: #62adf1;
      list-style: none;
      font-size: 16px;
      position: relative;
      padding: 5px 20px;
      cursor: pointer;
    }
  }

  .copyright {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 9pt;
    font-weight: lighter;

    a {
      color: $blue;
    }
  }
}
