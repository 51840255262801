@import 'src/legacy/Styles/Reset';
@import 'src/legacy/Styles/Colors';
@import 'src/legacy/Styles/Common';
@import 'src/legacy/Styles/Layout';

.input {
  position: relative;
  display: block;
  height: 40px;
  box-sizing: border-box;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &.select::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    right: 15px;
    width: 10px;
    height: 10px;
    @include arrow-down($dark-gray);
  }

  &.rich-text {
    min-height: 250px;
  }

  &.textarea {
    min-height: 100px;
  }

  label {
    position: absolute;
    top: -1px;
    left: 10px;
    font-size: 12px;
    transform: translateY(-50%);
    padding: 0px 5px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      transform: translateX(-5px);
      background-color: #fff;
      z-index: -1;
      height: 9.8px;
    }
  }

  input:not([type='checkbox']),
  select,
  textarea,
  .quill {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    padding: 10px 14px;
    outline: none;
    font-size: 15px;

    -webkit-appearance: none;

    border: 1px solid $gray;
    border-radius: 5px;

    &:disabled,
    &:read-only {
      background-color: $light-gray;
      cursor: initial;
    }

    &:focus:not(:read-only) {
      border-color: $light-blue;
    }
  }

  textarea {
    height: 100px;
    resize: none;
  }

  &.wrong input,
  &.wrong select,
  &.wrong .quill {
    border-color: $red;
  }

  i {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid $light-gray;
    padding: 8px 0;

    .ql-formats {
      margin-right: 10px;

      button {
        &:hover {
          color: $blue;
        }

        @media screen and (max-width: $mobile-width) {
          width: 25px;
        }
      }
    }
  }

  .ql-container.ql-snow {
    border: none;

    .ql-tooltip {
      top: calc(50% - 20px) !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .input-editor {
    min-height: 0px;
    transition: all 0.5s;

    &.expanded {
      min-height: 100px;
      transition: all 0.5s;
    }
  }
}

.checkbox {
  display: inline-block;
  height: initial;

  position: absolute;
  right: 50px;
  top: 10px;

  label {
    top: initial;
    left: initial;
    transform: translate(0%, 10%);
    margin: 0px 25px;
  }
}
.input label:before {
  background-color: transparent;
}
