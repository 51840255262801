@import './Colors.scss';

$mobile-width: 800px;

.container {
  width: 1250px;
  max-width: calc(100vw - 40px);
  position: relative;
  // left: 50%;
  // transform: translate(-50%);
  margin: auto;
}

.layout-steps {
  position: relative;

  &.hide-overflow {
    & > .layout-step {
      overflow: hidden;
      max-height: calc(100vh - 200px);

      &.current {
        overflow: initial;
        max-height: fit-content;
      }
    }
  }

  & > .layout-step {
    position: absolute;
    top: 0;

    width: 100%;
    // overflow: hidden;

    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    box-sizing: border-box;

    &.current {
      position: relative;
      top: 0;
      visibility: inherit;
      opacity: 1;

      transition: opacity 0.5s;
    }
  }
}

.manage-two-columns-layout {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 40px;

  @media screen and (min-width: $mobile-width) {
    .manage-window {
      position: sticky;
      top: 20px;
    }
  }

  @media screen and (max-width: $mobile-width) {
    &:not(.non-responsive) {
      display: block;
    }
  }
}

.two-columns-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media screen and (max-width: $mobile-width) {
    &:not(.non-responsive) {
      display: block;
    }
  }
}

.expandible-two-columns-layout {
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-gap: 0px;
  margin-bottom: 20px;

  transition: all 0.5s;

  .appear {
    width: 500px;
    opacity: 0;

    transition: all 0.5s;

    @media screen and (max-width: $mobile-width) {
      .manage-window {
        width: calc(100vw - 40px);
        position: fixed;
        left: 100vw;
        top: 20px;
      }
    }
  }

  &.expand {
    grid-template-columns: 1fr 500px;
    grid-gap: 20px;

    transition: all 0.5s;

    .appear {
      opacity: 1;

      // transition: all 0.5s;

      @media screen and (max-width: $mobile-width) {
        .manage-window {
          width: calc(100vw - 40px);
          position: fixed;
          left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-width) {
    &:not(.non-responsive) {
      display: block;
      // position: absolute;
    }
  }
}

.stat-container {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: $mobile-width) {
    &:not(.one) {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      width: calc(100% + 40px);
      box-sizing: border-box;
      margin-left: -20px;
      padding-left: 20px;
      display: block;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      div {
        width: 180px;
        margin-right: 20px;
        white-space: normal;
        display: inline-block;
      }
    }
  }

  &.one {
    grid-template-columns: repeat(1, 1fr);
  }

  &.two {
    grid-template-columns: repeat(2, 1fr);
  }

  &.four {
    grid-template-columns: repeat(4, 1fr);
  }
}

.manage-area {
  border: 1px solid $light-gray;
  border-radius: 5px;
  padding: 20px;
  // margin-top: 20px;

  h2 {
    margin-top: 0;
  }

  .input {
    margin-top: 15px;
  }
}

.narrow-container {
  width: 500px;
  margin: auto;
  max-width: calc(100vw - 40px);
}

.mobile-scrollable {
  @media screen and (max-width: $mobile-width) {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    padding: 0 20px;
    max-width: 100vw;
    width: 100vw;
    margin-left: -20px;
    box-sizing: border-box;
  }
}
