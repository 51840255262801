/*
  Inserting css reset piece by piece, to keep compatibility
  https://github.com/chakra-ui/chakra-ui/blob/main/packages/css-reset/src/css-reset.tsx
*/

*,
::before,
::after {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
}

// End css reset

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fixed-body-no-scroll{
  position: fixed!important;
  overscroll-behavior-y: none!important;
}