@import 'src/legacy/Styles/Colors';

.inner-auth-section {
  padding: 20px;
}

.auth-section {
  // padding: 20px;
  color: $black;

  h1 {
    margin-top: 0;
  }

  .google-button {
    width: 100%;

    .google-blue {
      background: $google-blue;
    }

    button {
      color: #fff;
      display: block;
      width: 100%;
    }
  }

  .input:not(:last-child) {
    margin-bottom: 20px;
  }

  span.error {
    color: $red;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .link {
    color: $light-blue;
    text-decoration: none;
    appearance: none;
    -webkit-appearance: none;
    font: inherit;
    border: none;
    padding: 0;
    background: transparent;
    cursor: pointer;
  }

  .link-to-other-sections {
    text-align: center;
    padding-top: 15px;
    border-top: 1px solid $light-gray;
  }

  .center-text {
    text-align: center;
    padding: 15px;
  }

  .password-hint {
    @extend .center-text;
    padding-top: 0;
    font-size: 14px;
    color: $black;
  }

  .instructions {
    display: block;
    margin-bottom: 20px;
    color: $dark-gray;
  }

  .icon-feedback {
    color: $blue;
    font-size: 100px;
    display: block;
    text-align: center;
    margin-bottom: -20px;
  }
}

.auth-feedback {
  font-family: 'Montserrat', sans-serif;
  padding: 20px;

  h1,
  h2 {
    text-align: center;
  }

  h2 {
    color: $dark-gray;
    font-weight: normal;
    font-size: 20px;
  }
}
