@import 'src/legacy/Styles/Reset';
@import 'src/legacy/Styles/Colors';

@mixin light-gradient($color) {
    background: linear-gradient(
        45deg,
        lighten($color, 0%) 0%,
        lighten($color, 20%) 100%
    );
}

@keyframes spinning {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loading-ball {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.button {
    height: 40px;
    padding: 0px 20px;
    border-radius: 40px;
    border: none;
    position: relative;
    font: inherit;
    cursor: pointer;

    i {
        margin-right: 10px;
    }

    &.full-width {
        width: 100%;
    }

    &.with-border {
        border: 2px solid;
    }

    &.transparent,
    &.loading-button {
        background-color: transparent;
        border-color: $gray;
    }

    &.gray {
        background-color: $gray;
        color: $black;
        // border: 1px solid #000;
    }

    &.lighter {
        background-color: $lighter-3;
        color: $black;
    }

    //colors
    &.blue {
        background: $blue-gradient;
        color: #fff;
    }

    &.red {
        @include light-gradient($red);
        color: #fff;
    }

    &.solid-blue {
        background: $blue;
        color: #fff;
    }

    &.facebook-blue {
        background: $facebook-blue;
        color: #fff;
    }

    &.loading {
        .inner {
            opacity: 0;
            transition: all 0.3s;
        }

        .loading-balls {
            opacity: 1;
            transition: all 0.3s;

            .ball {
                animation-play-state: running;
            }
        }
    }

    &.loading-button {
        $transition-duration: 0.3s;

        max-width: 300px;
        color: $black;
        position: relative;
        border: 3px solid;
        border-color: $gray;
        white-space: nowrap;

        transition: all $transition-duration;

        &::after {
            content: '';
            width: 0px;
            height: 46px;
            position: absolute;
            left: 50%;
            top: -3px;
            transform: translateX(-50%);
            background-color: #fff;

            transition: width $transition-duration;
        }

        &.loading {
            color: transparent;
            max-width: 40px;
            box-sizing: border-box;
            padding: 0px;

            animation-name: spinning;
            animation-duration: 3s;
            animation-play-state: running;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-delay: $transition-duration;

            transition: all $transition-duration;

            &::after {
                width: 10px;

                transition: width $transition-duration;
            }

            .inner {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
            }

            .loading-balls {
                display: none;
            }
        }
    }

    .inner {
        opacity: 1;
        transition: all 0.3s;
        position: relative;
    }

    .loading-balls {
        opacity: 0;
        transition: all 0.3s;
        display: flex;
        justify-content: space-between;

        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;

        .ball {
            width: 10px;
            height: 10px;
            background-color: #fff;
            border-radius: 10px;

            animation-name: loading-ball;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-play-state: paused;

            &:nth-child(1) {
                animation-delay: 0s;
            }

            &:nth-child(2) {
                animation-delay: 0.3s;
            }

            &:nth-child(3) {
                animation-delay: 0.6s;
            }
        }
    }
}
