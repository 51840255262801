input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: none; */
  -webkit-text-fill-color: inherit;
  /* -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset; */
  background-color: transparent;
  transition: background-color 50000s ease-in-out 0s;
}