@import 'src/legacy/Styles/Layout';

.main-header {
    background-color: #fff;

    &.attach {
        position: fixed;
        top: 0;
    }

    .header {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img.logo {
            height: 35px;
        }

        div.right {
            & > button.publish-event {
                float: left;
                margin-top: 10px;
                @media screen and (max-width: $mobile-width) {
                    display: none;
                }
            }
            button.publish-event + button.publish-event {
                margin-left: 15px;
            }
        }

        .main-menu-button {
            padding: 10px;
            font-size: 40px;
            line-height: 40px;
            margin-left: 10px;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &:hover {
                color: lighten($black, 20%);
            }
        }

        .organizers-page {
            color: #62adf1;
        }
    }
}
