// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,900');
@import './Colors.scss';
@import './Layout.scss';

.common-page {
    h1 {
        font-family: 'Montserrat', sans-serif;
        color: $black;
    }
}

.centered {
    position: absolute;
    left: 50%;
    top: 50%;
    width: fit-content;
    height: fit-content;

    transform: translate(-50%, -50%);
}

%box-shadow {
    // box-shadow: 0px 0px 20px -5px rgba(0,0,0,0.75);
    box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.3);
}

.form {
    h1:first-child,
    h2:first-child {
        margin-top: 0;
    }

    .input:not(:last-child) {
        margin-bottom: 20px;
    }

    & > *:last-child {
        margin-top: 20px;
    }
}

.or {
    display: block;
    text-align: center;
    position: relative;
    margin: 20px 0px;
    text-transform: uppercase;
    z-index: 1;

    &:before {
        content: '';
        display: block;
        border-bottom: 1px solid $light-gray;
        width: 100%;
        top: 50%;

        position: absolute;
        z-index: -1;
    }

    &:after {
        content: '';
        display: block;

        width: 50px;
        height: 20px;
        background-color: #fff;
        top: 50%;
        left: calc(50% - 25px);

        position: absolute;
        z-index: -1;
    }
}

.badge {
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;

    &.red {
        background-color: $red;
    }

    &.green {
        background-color: $green;
    }

    &.gray {
        background-color: $dark-gray;
    }
}

.hide-desktop {
    display: none;
    @media screen and (max-width: $mobile-width) {
        display: block;
    }
}

.hide-mobile {
    display: block;
    @media screen and (max-width: $mobile-width) {
        display: none;
    }
}

.show-mobile {
    display: none;
    @media screen and (max-width: $mobile-width) {
        display: block;
    }
}

.bold {
    font-weight: bold;
}

@mixin arrow-down($color) {
    border: 2px solid $color;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
}
