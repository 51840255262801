@import './Colors';

body {
  margin: 0 auto;
  color: $black;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

button,
select,
input:not([type='checkbox']) {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
}
