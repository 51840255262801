//colors

$red: #f44336;

$orange: #ff8629;

$green: #4caf50;

$purple: #9c27b0;

$yellow: #ffce29;

$blue: #62adf1;
$light-blue: #00b0ff;
$facebook-blue: #3b5998;
$google-blue: #4688f1;

$light-gray: #f2f2f2;
$gray: #e5e5e5;
$dark-gray: rgba(0, 0, 0, 0.3);
$darker-gray: rgba(0, 0, 0, 0.5);

$lighter-3: rgba(255, 255, 255, 0.3);
$lighter-6: rgba(255, 255, 255, 0.6);

//gradients
$blue-gradient: linear-gradient(to left, #62adf1 0%, #005eea 100%);

$black: #424242;
$white: #fff;
